/**
 * @name: 一对一课程订单接口
 * @author: itmobai
 * @date: 2023-06-01 16:13
 * @description：一对一课程订单接口
 * @update: 2023-06-01 16:13
 */
import {get} from "@/request";
import {IOneOrder, IOneOrderParam} from "@/apis/order/one/types";
import {IPageRes} from "@/apis/page";

/**
 * 一对一课程订单查询
 * @param params 查询参数
 */
export const orderQueryOneOnOneOrderByPageApi = (params: IOneOrderParam) => get<IPageRes<IOneOrder[]>>("/golf/order/queryOneOnOneOrderByPage", params)
/**
 * 一对一课程订单导出
 * @param params 查询参数
 */
export const orderOneOnOneOrderExportApi = (params: IOneOrderParam) => get("/golf/order/oneOnOneOrderExport", params, 'blob')
/**
 * 查看核销码列表
 * @param orderId
 */
export const orderQueryCouponCodeListApi = (orderId: string) => get("/golf/order/queryCouponCodeList", {orderId})
