
/**
 * @name: 一对一课程订单
 * @author: itmobai
 * @date: 2023-06-01 16:09
 * @description：一对一课程订单
 * @update: 2023-06-01 16:09
 */
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {IOneOrder, IOneOrderParam} from "@/apis/order/one/types";
import {deepCopy, exportFile} from "@/utils/common";
import {
  orderOneOnOneOrderExportApi,
  orderQueryCouponCodeListApi,
  orderQueryOneOnOneOrderByPageApi
} from "@/apis/order/one";
import QRCode from "qrcodejs2";

@Component({})
export default class OneOrderPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: IOneOrder[] = []
  // 表单
  modelForm: Partial<IOneOrder> = {}
  // 查询参数
  queryParam: IOneOrderParam = {
    page: 1,
    limit: 10
  }
  // crudOption
  option: ICrudOption = {
    column: [
      {
        "label": "订单编号",
        "prop": "orderSn",
        "align": "left",
        "width": "180",
        "search": true
      },
      {
        "label": "课程名称",
        "prop": "courseName",
        "align": "center",
        "width": "150",
        "search": true,
        "overHidden": true
      },
      {
        "label": "教练",
        "prop": "coachName",
        "align": "center",
        "width": "150",
        "overHidden": true,
        "search": true
      },
      {
        "label": "课程地区",
        "prop": "courseLocation",
        "align": "center",
        "width": "150"
      },
      {
        "label": "下单客户",
        "prop": "orderCustom",
        "align": "center",
        "width": "150",
        "search": true,
        "overHidden": true
      },
      {
        "label": "课程金额",
        "prop": "courseAmount",
        "align": "center",
        "width": "150"
      },
      {
        "label": "订单积分",
        "prop": "integral",
        "align": "center",
        "width": "150"
      },
      {
        "label": "购买卡次",
        "prop": "courseCardCount",
        "align": "center",
        "width": "150"
      },
      {
        "label": "已使用",
        "prop": "usedCourseNumber",
        "align": "center",
        "width": "150"
      },
      {
        "label": "支付方式",
        "prop": "payWay",
        "align": "center",
        "width": "150",
        "search": true,
        "type": "select",
        "dicData": [
          {
            "label": "余额",
            "value": 1
          },
          {
            "label": "微信支付",
            "value": 2
          }
        ]
      },
      {
        "label": "订单状态",
        "prop": "courseOrderStatus",
        "align": "center",
        "width": "150",
        "search": true,
        "type": "select",
        "dicData": [
          {
            "label": "待支付",
            "value": 1,
            "color": "#F56C6C"
          },
          {
            "label": "待使用",
            "value": 2,
            "color": "#409EFF"
          },
          {
            "label": "使用中",
            "value": 3,
            "color": "#409EFF"
          },
          {
            "label": "已完成",
            "value": 4,
            "color": "#67C23A"
          },
          {
            "label": "已取消",
            "value": 5,
            "color": "#F56C6C"
          }
        ]
      },
      {
        "label": "下单时间",
        "prop": "payTime",
        "align": "center",
        "width": "180",
        "search": true,
        "type": "daterange"
      }
    ]
  }
  // 核销码弹窗
  verificationCodeDialog: boolean = false;
  verificationCodeList: any = []
  // 核销码单独弹窗
  verificationDialog: boolean = false

  /**
   * 导出excel
   */
  exportExcel () {
    const query: IOneOrderParam = deepCopy(this.queryParam)
    if (query.payTime && query.payTime.length === 2) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ""
      query.payTimeEnd = ""
    }
    delete query.payTime
    orderOneOnOneOrderExportApi(query).then(e => {
      exportFile(e, '1v1课程订单.xlsx')
    })
  }

  /**
   * 查询
   */
  getList () {
    this.tableLoading = true
    const query: IOneOrderParam = deepCopy(this.queryParam)
    if (query.payTime && query.payTime.length === 2) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ""
      query.payTimeEnd = ""
    }
    delete query.payTime
    orderQueryOneOnOneOrderByPageApi(query).then(e => {
      this.tableLoading = false
      this.tableData = e.list
      this.tableTotal = e.total
    })
  }

  /**
   * 查看核销码
   * @param orderId
   */
  openVerificationCode (orderId: string) {
    orderQueryCouponCodeListApi(orderId).then(e => {
      this.verificationCodeList = e;
      this.verificationCodeDialog = true
      setTimeout(() => {
        this.$nextTick(() => {
          for (let i = 0; i < e.length; i++) {
            const dom = document.querySelector(`#qrcode-${i}`)
            dom!.innerHTML = ""
            new QRCode(dom, {
              text: e[i]['ticketCode'],
              width: 100,
              height: 100
            })
          }
        })
      }, 0)
    })
  }

  openVerification (code: string) {
    this.verificationDialog = true
    setTimeout(() => {
      this.$nextTick(() => {
        const dom = document.querySelector(`#verification`)
        dom!.innerHTML = ""
        new QRCode(dom, {
          text: code,
          width: 250,
          height: 250
        })
      })
    }, 0)
  }

  created () {
    this.getList()
  }
}
